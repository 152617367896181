
span.number {
    position: relative;
    display: inline-block;
  
    > span {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
  
    &:hover {
      //color: rgba(0, 0, 0, 0);
  
      > span {
        background-color: inherit;
        display: block;
        z-index: 1000;
        box-shadow: 0px 0px 5px 1px rgba(100, 100, 100, 0.5);
  
        ::before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          margin-right: -0.25em; /* Adjusts for spacing */
        }
  
      }
    }
  }