.figure-box {
    position: relative;
    float: left;
    margin: 10px;
    &.show-params{
        margin-left: 110px;
    }
    .params-panel {
        position: absolute;
        top: 0;
        left: -100px;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 12px;
        margin-left: 0;
        padding-left: 0;
    }
    .coord-values .value {
        position: absolute;
        font-size: 10px;
        &.minReal{
            bottom: -7px;
            left: 0;
        }
        &.maxReal{
            bottom: -7px;
            right: 0;
        }
        &.minImag{
            transform: translateX(-100%) rotate(-90deg) translateX(100%);
            bottom: 0;
            left: -5px;
        }
        &.maxImag {
            transform: translateX(-100%) rotate(-90deg) translateX(0%);
            top:0;
            left: -5px;
        }

        &.minImag, &.maxImag {
            transform-origin: right;
        }
    }
}